import React from 'react'
import { Link, graphql, useStaticQuery } from "gatsby"
import Facebook from '../images/facebook.svg'
import Linkedin from '../images/linkedin.svg'
import Instagram from '../images/instagram.svg'
import Twitter from '../images/twitter.svg'
import Dubai from '../images/dubai.svg'
import India from '../images/india_gate.svg'
const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    author
                }
            }
        }
    `)

    return (
        <footer className="bg-black pt-32 lg:pt-36 xl:pt-40 2xl:pt-44 relative mt-16 sm:mt-16 md:mt-14">
            <div className="ready_for_project absolute left-0 top-0 w-full transform  -translate-y-2/4">
                <div className="container">
                    <div class="flex flex-wrap justify-center md:justify-between bg-white w-full lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl m-auto py-6 sm:py-8 2xl:py-10 px-6 sm:px-8 2xl:px-12 shadow-footer">
                        <h3 class="font-medium text-center md:text-left pb-4 md:pb-0 w-full md:w-auto text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-32 2xl:text-40 leading-none items-center flex justify-center md:justify-start">Ready for next project?</h3>
                        <div class="btn-group footer-btn"><a title="Contact Us" class="btn bg-pink" href="/contact">Contact Us</a></div>
                    </div>

                </div>
            </div>
            <div className="container">
                <div className="flex rounded-3xl  flex-wrap justify-center md:justify-between w-full xl:shadow-footer ">

                    <div className='w-full flex flex-wrap mb-12'>
                        <div className='flex flex-wrap -mx-0 md:-mx-4'>
                            <div className="w-100full lg:w-50half hours_plan bg-white rounded-3xl px-7 py-7 pt-16 mx-0 md:mx-4 shadow-hireusbox mt-10 lg:mt-10 relative">
                                <h6 className="p-3 border-black  border-8 absolute bg-white min-w-90 min-h-90 2xl:min-w-100 2xl:min-h-100 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg"><img src={Dubai} /></h6>
                                <ul className="">
                                    <h4 className=' text-22 md:text-26 lg:text-3xl  font-bold text-left mb-5 text-pink'>Dubai</h4>
                                    <li className="leading-none mb-4">
                                        <a className="relative pl-8 text-black font-light hover:text-pink transition duration-150 ease-in-out" href="tel:(+91) 8128531758" title="(+91) 8128531758">(+971) 050 7185309
                                            <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-black hover:fill-pink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" >
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                                    <path className="st0" d="M916,5105c-81-21-164-67-249-140c-94-81-431-424-486-495c-223-285-227-728-11-1300c263-699,756-1407,1380-1982
                                                                                            C2410,395,3562-118,4155,26c88,21,234,89,301,140c68,51,520,515,562,576c78,113,108,264,78,392c-34,143-45,156-480,592
                                                                                            c-439,439-453,450-598,480c-115,24-232,3-345-62c-26-16-150-129-275-253l-227-224l-73,38c-179,93-311,182-508,344
                                                                                            c-202,166-484,457-640,661c-113,148-279,421-280,458c0,7,78,89,173,182c200,195,279,286,324,372c56,109,67,247,28,361
                                                                                            c-39,116-77,160-459,543c-378,378-436,428-544,465C1117,5118,993,5124,916,5105z M1148,4824c28-16,188-167,403-383
                                                                                            c389-390,404-408,403-511c0-91-24-123-285-384c-132-131-247-252-255-269c-12-21-15-50-12-99c3-60,11-83,72-204
                                                                                            c138-274,320-515,621-823c299-306,597-534,904-692c109-56,130-64,180-63c34,0,70,7,91,18c19,10,143,126,275,257
                                                                                            c170,169,253,244,285,258c60,27,138,27,193,0c60-30,767-736,798-798c17-33,24-63,24-106c0-103-21-131-305-416
                                                                                            c-281-282-307-301-457-335c-145-32-388-6-634,67c-375,111-879,377-1294,683C1356,1612,670,2511,385,3342c-136,395-151,678-47,888
                                                                                            c32,67,62,101,270,312c128,130,251,248,272,263C970,4867,1062,4874,1148,4824z" />
                                                </g>
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="leading-none mb-4">
                                        <a className="relative pl-8 text-black font-light hover:text-pink transition duration-150 ease-in-out" href="mailto:sales@ewisesolutions.com" title="sales@ewisesolutions.com">sales@ewisesolutions.com
                                            <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-black hover:fill-pink"
                                                version="1.1"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 512 512">
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                                    <path className="st0"
                                                        d="M340,4469c-160-31-299-173-330-339c-14-72-14-3067,0-3141c32-169,170-307,340-339c73-14,4347-14,4420,0
                        c170,32,308,170,340,339c14,74,14,3068,0,3142c-32,169-170,307-340,339C4703,4483,405,4482,340,4469z M4714,4261
                        c19-7-210-221-1517-1409c-526-479-507-463-552-483c-51-24-119-24-171,0c-38,17-508,440-1628,1465c-378,346-455,421-440,427
                        C431,4271,4689,4271,4714,4261z M1066,3344c462-421,840-769,842-773c1-5-91-91-205-192c-288-255-641-580-1086-998
                        c-208-196-381-357-386-359s-11,7-15,20c-8,30-8,2996,0,3037c4,17,8,31,9,31C227,4110,605,3766,1066,3344z M4909,2565
                        c0-825-2-1510-5-1523c-4-13-10-22-15-20s-178,163-386,359c-445,418-798,743-1086,998c-114,101-206,187-205,192c2,4,381,353,843,775
                        c763,697,840,764,846,743C4905,4076,4908,3390,4909,2565z M2197,2314c171-153,278-196,426-173c98,15,170,57,301,174
                        c102,92,120,104,135,93c120-94,675-599,1249-1137c380-356,430-407,410-413c-13-3-984-6-2158-6s-2145,3-2158,6c-20,6,31,57,410,413
                        c238,223,516,482,618,575c192,177,639,574,645,574C2077,2420,2132,2373,2197,2314z" />
                                                </g>
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="">
                                        <p className="relative pl-8 text-black text-base 2xl:text-base font-light leading-26 ">P2-ELOB Office NO. E2-110G-17
                                            Al Hamriyah Free Zone - Sharjah
                                            Dubai, United Arab Emirates
                                            P.O Box: 0 HFZA, SHJ, UAE
                                            <svg className="svg_icons absolute max-w-21 max-h-21 left-0 top-0  fill-black"
                                                version="1.0"
                                                viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet">

                                                <g className=""
                                                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                    stroke="none">
                                                    <path d="M2425 5114 c-459 -46 -827 -216 -1135 -524 -542 -542 -682 -1357
            -351 -2045 69 -144 1497 -2454 1547 -2502 62 -60 160 -53 215 17 40 52 1415
            2351 1465 2450 82 164 136 328 171 520 21 116 23 425 4 545 -63 398 -234 738
            -511 1015 -275 275 -624 452 -1009 510 -80 12 -330 21 -396 14z m345 -309
            c311 -44 625 -201 849 -426 211 -211 348 -467 412 -769 33 -152 33 -430 1
            -580 -30 -139 -74 -264 -132 -380 -27 -52 -334 -571 -683 -1153 l-634 -1059
            -661 1065 c-363 586 -681 1105 -705 1153 -98 196 -145 379 -154 604 -27 691
            414 1304 1077 1499 199 59 420 75 630 46z" />
                                                    <path d="M2397 4205 c-338 -64 -617 -324 -708 -660 -31 -114 -32 -335 -1 -450
            68 -251 244 -466 475 -580 142 -70 224 -88 397 -88 173 -1 253 17 397 88 232
            114 407 328 475 580 31 115 30 336 -1 450 -85 312 -338 565 -646 645 -106 28
            -283 35 -388 15z m285 -296 c96 -20 208 -80 284 -152 77 -72 124 -143 162
            -245 22 -58 26 -84 26 -187 1 -128 -9 -175 -62 -280 -72 -147 -231 -271 -393
            -310 -373 -88 -738 200 -739 582 0 98 15 163 61 262 72 155 233 286 403 327
            65 16 190 17 258 3z" />
                                                </g>
                                            </svg>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-100full  lg:w-50half hours_plan bg-white rounded-3xl px-7 py-7 pt-16 mx-0 md:mx-4 shadow-hireusbox mt-16 md:mt-16 lg:mt-10 relative">
                                <h6 className="p-3 border-black  border-8 absolute bg-white min-w-90 min-h-90 2xl:min-w-100 2xl:min-h-100 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg"><img src={Dubai} /></h6>
                                <ul className="">
                                    <h4 className=' text-22 md:text-26 lg:text-3xl  font-bold text-left mb-5 text-pink'>Kenya</h4>
                                    <li className="leading-none mb-4">
                                        <a className="relative pl-8 text-black font-light hover:text-pink transition duration-150 ease-in-out" href="tel:(+254) 754333748" title="(+254) 754333748">(+254)754333748
                                            <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-black hover:fill-pink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" >
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                                    <path className="st0" d="M916,5105c-81-21-164-67-249-140c-94-81-431-424-486-495c-223-285-227-728-11-1300c263-699,756-1407,1380-1982
                                                                                            C2410,395,3562-118,4155,26c88,21,234,89,301,140c68,51,520,515,562,576c78,113,108,264,78,392c-34,143-45,156-480,592
                                                                                            c-439,439-453,450-598,480c-115,24-232,3-345-62c-26-16-150-129-275-253l-227-224l-73,38c-179,93-311,182-508,344
                                                                                            c-202,166-484,457-640,661c-113,148-279,421-280,458c0,7,78,89,173,182c200,195,279,286,324,372c56,109,67,247,28,361
                                                                                            c-39,116-77,160-459,543c-378,378-436,428-544,465C1117,5118,993,5124,916,5105z M1148,4824c28-16,188-167,403-383
                                                                                            c389-390,404-408,403-511c0-91-24-123-285-384c-132-131-247-252-255-269c-12-21-15-50-12-99c3-60,11-83,72-204
                                                                                            c138-274,320-515,621-823c299-306,597-534,904-692c109-56,130-64,180-63c34,0,70,7,91,18c19,10,143,126,275,257
                                                                                            c170,169,253,244,285,258c60,27,138,27,193,0c60-30,767-736,798-798c17-33,24-63,24-106c0-103-21-131-305-416
                                                                                            c-281-282-307-301-457-335c-145-32-388-6-634,67c-375,111-879,377-1294,683C1356,1612,670,2511,385,3342c-136,395-151,678-47,888
                                                                                            c32,67,62,101,270,312c128,130,251,248,272,263C970,4867,1062,4874,1148,4824z" />
                                                </g>
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="leading-none mb-4">
                                        <a className="relative pl-8 text-black font-light hover:text-pink transition duration-150 ease-in-out" href="mailto:sales@ewisesolutions.com" title="sales@ewisesolutions.com">indokenyapetro@gmail.com
                                            <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-black hover:fill-pink"
                                                version="1.1"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 512 512">
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                                    <path className="st0"
                                                        d="M340,4469c-160-31-299-173-330-339c-14-72-14-3067,0-3141c32-169,170-307,340-339c73-14,4347-14,4420,0
                        c170,32,308,170,340,339c14,74,14,3068,0,3142c-32,169-170,307-340,339C4703,4483,405,4482,340,4469z M4714,4261
                        c19-7-210-221-1517-1409c-526-479-507-463-552-483c-51-24-119-24-171,0c-38,17-508,440-1628,1465c-378,346-455,421-440,427
                        C431,4271,4689,4271,4714,4261z M1066,3344c462-421,840-769,842-773c1-5-91-91-205-192c-288-255-641-580-1086-998
                        c-208-196-381-357-386-359s-11,7-15,20c-8,30-8,2996,0,3037c4,17,8,31,9,31C227,4110,605,3766,1066,3344z M4909,2565
                        c0-825-2-1510-5-1523c-4-13-10-22-15-20s-178,163-386,359c-445,418-798,743-1086,998c-114,101-206,187-205,192c2,4,381,353,843,775
                        c763,697,840,764,846,743C4905,4076,4908,3390,4909,2565z M2197,2314c171-153,278-196,426-173c98,15,170,57,301,174
                        c102,92,120,104,135,93c120-94,675-599,1249-1137c380-356,430-407,410-413c-13-3-984-6-2158-6s-2145,3-2158,6c-20,6,31,57,410,413
                        c238,223,516,482,618,575c192,177,639,574,645,574C2077,2420,2132,2373,2197,2314z" />
                                                </g>
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="">
                                        <p className="relative pl-8 text-black text-base 2xl:text-base font-light leading-26 ">
                                            P.O.Box 41095-80100,
                                            Mombasa-Kenya
                                            Plot LR No. KAWALA "A" Kadzonzo
                                            -/399, KoKotoni, Kilifi country
                                            <svg className="svg_icons absolute max-w-21 max-h-21 left-0 top-0  fill-black"
                                                version="1.0"
                                                viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet">

                                                <g className=""
                                                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                    stroke="none">
                                                    <path d="M2425 5114 c-459 -46 -827 -216 -1135 -524 -542 -542 -682 -1357
            -351 -2045 69 -144 1497 -2454 1547 -2502 62 -60 160 -53 215 17 40 52 1415
            2351 1465 2450 82 164 136 328 171 520 21 116 23 425 4 545 -63 398 -234 738
            -511 1015 -275 275 -624 452 -1009 510 -80 12 -330 21 -396 14z m345 -309
            c311 -44 625 -201 849 -426 211 -211 348 -467 412 -769 33 -152 33 -430 1
            -580 -30 -139 -74 -264 -132 -380 -27 -52 -334 -571 -683 -1153 l-634 -1059
            -661 1065 c-363 586 -681 1105 -705 1153 -98 196 -145 379 -154 604 -27 691
            414 1304 1077 1499 199 59 420 75 630 46z" />
                                                    <path d="M2397 4205 c-338 -64 -617 -324 -708 -660 -31 -114 -32 -335 -1 -450
            68 -251 244 -466 475 -580 142 -70 224 -88 397 -88 173 -1 253 17 397 88 232
            114 407 328 475 580 31 115 30 336 -1 450 -85 312 -338 565 -646 645 -106 28
            -283 35 -388 15z m285 -296 c96 -20 208 -80 284 -152 77 -72 124 -143 162
            -245 22 -58 26 -84 26 -187 1 -128 -9 -175 -62 -280 -72 -147 -231 -271 -393
            -310 -373 -88 -738 200 -739 582 0 98 15 163 61 262 72 155 233 286 403 327
            65 16 190 17 258 3z" />
                                                </g>
                                            </svg>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-100full lg:w-50half month_plan bg-white rounded-3xl px-7 py-7 pt-16 mx-0 md:mx-4 shadow-hireusbox mt-16 md:mt-16 lg:mt-10 relative">
                                <h6 className="p-5 border-black border-8 absolute bg-white min-w-90 min-h-90 2xl:min-w-100 2xl:min-h-100 rounded-full flex-wrap flex justify-center items-center text-white top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold text-lg"><img src={India} /></h6>
                                <ul className="">
                                    <h4 className=' text-22 md:text-26 lg:text-3xl  font-bold text-left mb-5 text-pink'>India</h4>
                                    <li className="leading-none mb-4">
                                        <a className="relative pl-8 text-black font-light hover:text-pink transition duration-150 ease-in-out" href="tel:(+91) 8128531758" title="(+91) 8128531758">(+91) 8128531758
                                            <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-black hover:fill-pink" version="1.1" x="0px" y="0px" viewBox="0 0 512 512" >
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                                    <path className="st0" d="M916,5105c-81-21-164-67-249-140c-94-81-431-424-486-495c-223-285-227-728-11-1300c263-699,756-1407,1380-1982
                                                                                            C2410,395,3562-118,4155,26c88,21,234,89,301,140c68,51,520,515,562,576c78,113,108,264,78,392c-34,143-45,156-480,592
                                                                                            c-439,439-453,450-598,480c-115,24-232,3-345-62c-26-16-150-129-275-253l-227-224l-73,38c-179,93-311,182-508,344
                                                                                            c-202,166-484,457-640,661c-113,148-279,421-280,458c0,7,78,89,173,182c200,195,279,286,324,372c56,109,67,247,28,361
                                                                                            c-39,116-77,160-459,543c-378,378-436,428-544,465C1117,5118,993,5124,916,5105z M1148,4824c28-16,188-167,403-383
                                                                                            c389-390,404-408,403-511c0-91-24-123-285-384c-132-131-247-252-255-269c-12-21-15-50-12-99c3-60,11-83,72-204
                                                                                            c138-274,320-515,621-823c299-306,597-534,904-692c109-56,130-64,180-63c34,0,70,7,91,18c19,10,143,126,275,257
                                                                                            c170,169,253,244,285,258c60,27,138,27,193,0c60-30,767-736,798-798c17-33,24-63,24-106c0-103-21-131-305-416
                                                                                            c-281-282-307-301-457-335c-145-32-388-6-634,67c-375,111-879,377-1294,683C1356,1612,670,2511,385,3342c-136,395-151,678-47,888
                                                                                            c32,67,62,101,270,312c128,130,251,248,272,263C970,4867,1062,4874,1148,4824z" />
                                                </g>
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="leading-none mb-4">
                                        <a className="relative pl-8 text-black font-light hover:text-pink transition duration-150 ease-in-out" href="mailto:sales@ewisesolutions.com" title="sales@ewisesolutions.com">sales@ewisesolutions.com
                                            <svg className="svg_icons absolute max-w-21 left-0 top-0 fill-black hover:fill-pink"
                                                version="1.1"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 512 512">
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                                    <path className="st0"
                                                        d="M340,4469c-160-31-299-173-330-339c-14-72-14-3067,0-3141c32-169,170-307,340-339c73-14,4347-14,4420,0
                        c170,32,308,170,340,339c14,74,14,3068,0,3142c-32,169-170,307-340,339C4703,4483,405,4482,340,4469z M4714,4261
                        c19-7-210-221-1517-1409c-526-479-507-463-552-483c-51-24-119-24-171,0c-38,17-508,440-1628,1465c-378,346-455,421-440,427
                        C431,4271,4689,4271,4714,4261z M1066,3344c462-421,840-769,842-773c1-5-91-91-205-192c-288-255-641-580-1086-998
                        c-208-196-381-357-386-359s-11,7-15,20c-8,30-8,2996,0,3037c4,17,8,31,9,31C227,4110,605,3766,1066,3344z M4909,2565
                        c0-825-2-1510-5-1523c-4-13-10-22-15-20s-178,163-386,359c-445,418-798,743-1086,998c-114,101-206,187-205,192c2,4,381,353,843,775
                        c763,697,840,764,846,743C4905,4076,4908,3390,4909,2565z M2197,2314c171-153,278-196,426-173c98,15,170,57,301,174
                        c102,92,120,104,135,93c120-94,675-599,1249-1137c380-356,430-407,410-413c-13-3-984-6-2158-6s-2145,3-2158,6c-20,6,31,57,410,413
                        c238,223,516,482,618,575c192,177,639,574,645,574C2077,2420,2132,2373,2197,2314z" />
                                                </g>
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="">
                                        <p className="relative pl-8 text-black text-base 2xl:text-base font-light leading-26 ">603, City Centre, Opp.  Shukan Mall, Science City Road,Ahmedabad-380060
                                            <svg className="svg_icons absolute max-w-21 max-h-21 left-0 top-0  fill-black"
                                                version="1.0"
                                                viewBox="0 0 512.000000 512.000000"
                                                preserveAspectRatio="xMidYMid meet">

                                                <g className=""
                                                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                    stroke="none">
                                                    <path d="M2425 5114 c-459 -46 -827 -216 -1135 -524 -542 -542 -682 -1357
            -351 -2045 69 -144 1497 -2454 1547 -2502 62 -60 160 -53 215 17 40 52 1415
            2351 1465 2450 82 164 136 328 171 520 21 116 23 425 4 545 -63 398 -234 738
            -511 1015 -275 275 -624 452 -1009 510 -80 12 -330 21 -396 14z m345 -309
            c311 -44 625 -201 849 -426 211 -211 348 -467 412 -769 33 -152 33 -430 1
            -580 -30 -139 -74 -264 -132 -380 -27 -52 -334 -571 -683 -1153 l-634 -1059
            -661 1065 c-363 586 -681 1105 -705 1153 -98 196 -145 379 -154 604 -27 691
            414 1304 1077 1499 199 59 420 75 630 46z" />
                                                    <path d="M2397 4205 c-338 -64 -617 -324 -708 -660 -31 -114 -32 -335 -1 -450
            68 -251 244 -466 475 -580 142 -70 224 -88 397 -88 173 -1 253 17 397 88 232
            114 407 328 475 580 31 115 30 336 -1 450 -85 312 -338 565 -646 645 -106 28
            -283 35 -388 15z m285 -296 c96 -20 208 -80 284 -152 77 -72 124 -143 162
            -245 22 -58 26 -84 26 -187 1 -128 -9 -175 -62 -280 -72 -147 -231 -271 -393
            -310 -373 -88 -738 200 -739 582 0 98 15 163 61 262 72 155 233 286 403 327
            65 16 190 17 258 3z" />
                                                </g>
                                            </svg>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="-mx-4">
                    <div className="footer_section sm:pb-6 md:pb-14 lg:pb-16 xl:pb-18 2xl:pb-20">
                        <div className="footer_inner flex flex-wrap">
                            <div className="box w-full md:w-full lg:w-30 xl:w-30 2xl:w-30 px-4 pb-8 lg:pb-0">
                                <h6 className="text-white font-normal mb-4 2xl:mb-5">About Us</h6>
                                <p className="text-white leading-26 font-light">We are not just a recognized IT company but also a group of self-motivated
                                    and talented experts that help brands including enterprises, mid-size businesses and start-ups with innovative web
                                    development services.</p>
                            </div>
                            <div className="pb-8 md:pb-0 box w-full sm:w-3/6 md:w-1/3 lg:w-26p xl:w-26p 2xl:w-23 px-4 lg:pl-6 xl:pl-16 2xl:pl-70">
                                <h6 className="text-white font-normal mb-4 2xl:mb-5">Technologies</h6>
                                <ul>
                                    <li className="leading-none mb-4"><Link className="text-white font-light hover:text-pink transition duration-150 ease-in-out" to="/services#e-commerce" title="e-Commerce Development">e-Commerce Development</Link></li>
                                    <li className="leading-none mb-4"><Link className="text-white font-light hover:text-pink transition duration-150 ease-in-out" to="/services#web-development" title="Web Development">Web Development</Link></li>
                                    <li className="leading-none mb-4"><Link className="text-white font-light hover:text-pink transition duration-150 ease-in-out" to="/services#mobile-app-development" title="Mobile App Development">Mobile App Development</Link></li>
                                    <li className="leading-none"><Link className="text-white font-light hover:text-pink transition duration-150 ease-in-out" to="/services#other-services" title="Other Services">Other Services</Link></li>

                                </ul>
                            </div>
                            <div className="pb-8 md:pb-0 box w-full sm:w-3/6 md:w-1/3 lg:w-16p xl:w-20p 2xl:w-23 px-4 md:pl-12 lg:pl-6 xl:pl-10 2xl:pl-20">
                                <h6 className="text-white font-normal mb-4 2xl:mb-5">Quick Link</h6>
                                <ul>
                                    <li className="leading-none mb-4"><Link className="text-white font-light hover:text-pink transition duration-150 ease-in-out" to="/blog" title="Blog">Blog</Link></li>
                                    <li className="leading-none mb-4"><Link className="text-white font-light hover:text-pink transition duration-150 ease-in-out" to="/about-us" title="About Us">About Us</Link></li>
                                    <li className="leading-none mb-4"><Link className="text-white font-light hover:text-pink transition duration-150 ease-in-out" to="/contact" title="Contact Us">Contact Us</Link></li>

                                </ul>
                            </div>
                            <div className="pb-8 md:pb-0 box w-full sm:w-full md:w-1/3 lg:w-27p xl:w-23 2xl:w-23 px-4">
                                <h6 className="text-white font-normal mb-4 2xl:mb-5">Social Links</h6>
                                <ul className="flex">
                                    {/* <li className="mx-3">
                                    <a className="bg-gray w-10 h-10 sm:w-10 2xl:w-10 sm:h-10 2xl:h-10 rounded-full flex justify-center items-center hover:bg-pink transition duration-150 ease-in-out" href="" title="Facebook"> <img className="p-1" src={Facebook} alt="facebook" /> </a>
                                </li> */}
                                    <li className="mr-3">
                                        <a className="bg-white w-10 h-10 sm:w-10 2xl:w-10 sm:h-10 2xl:h-10 rounded-full flex justify-center items-center transition duration-150 ease-in-out" href="https://www.linkedin.com/company/ewise-solutions/" title="Linkedin"> <img className="p-2" src={Linkedin} alt="linkedin" /> </a>
                                    </li>
                                    {/* <li className="mx-3">
                                    <a className="bg-gray w-10 h-10 sm:w-10 2xl:w-10 sm:h-10 2xl:h-10 rounded-full flex justify-center items-center hover:bg-pink transition duration-150 ease-in-out" href="" title="Instagram"> <img className="p-1" src={Instagram} alt="instagram" /> </a>
                                </li>
                                <li className="mx-3">
                                    <a className="bg-gray w-10 h-10 sm:w-10 2xl:w-10 sm:h-10 2xl:h-10 rounded-full flex justify-center items-center hover:bg-pink transition duration-150 ease-in-out" href="" title="Twitter"> <img className="p-1" src={Twitter} alt="twitter" /> </a>
                                </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright_section bg-gray py-2 2xl:py-2.5">
                <div className="container">
                    <p className="text-center text-sm 2xl:text-base">Copyright ©2021 All rights reserved</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer