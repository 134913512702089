import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import eWiseLogo from '../images/icon.png'


const Header = () => {
  
   

  return (
    <header className="absolute left-0 top-0 w-full z-9999 bg-white xl:bg-transparent">
      <div className="container ">
        <div className="-mx-4">
          <div className="header flex items-center flex-wrap justify-between">
            <div className="header_left w-44 lg:w-52 xl:w-1/4 px-4">
              <div className="logo py-4">
                <Link to="/" title="eWise"><img className="mb-0 inline-block" src={eWiseLogo} alt="e-Wise Logo" /></Link>
              </div>
            </div>
            <label className=" mr-4 xl:mr-0 xl:hidden cursor-pointer flex items-center" htmlFor="menu-toggle">
              <svg className="w-7 h-7 lg:w-8 lg:h-8" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000" stroke="none">
                  <path d="M244 4471 c-94 -23 -177 -91 -217 -180 -32 -68 -31 -189 2 -255 32
                -65 90 -122 154 -152 l52 -24 2325 0 2325 0 52 24 c64 30 122 87 154 152 33
                66 34 187 2 255 -30 66 -86 124 -151 156 l-57 28 -2305 2 c-1268 0 -2319 -2
                -2336 -6z"/>
                  <path d="M230 2861 c-84 -26 -160 -90 -201 -171 -19 -37 -23 -61 -23 -130 0
                -72 4 -92 26 -135 31 -60 86 -114 148 -146 l45 -24 2335 0 2335 0 50 27 c111
                59 170 155 170 278 0 123 -59 219 -170 278 l-50 27 -2320 2 c-1276 1 -2331 -2
                -2345 -6z"/>
                  <path d="M169 1227 c-232 -120 -221 -439 20 -559 l46 -23 2325 0 2325 0 57 28
                c65 32 121 90 151 156 32 68 31 189 -2 255 -32 65 -90 122 -154 152 l-52 24
                -2327 0 -2326 0 -63 -33z"/>
                </g>
              </svg>
            </label>
            <input className="hidden" type="checkbox" id="menu-toggle"></input>
            <div className="pt-0 xl:pt-0 justify-end hidden xl:flex header_right w-full xl:w-3/4 px-0 xl:px-4 max-h-82 sm:max-h-82 md:max-h-82 lg:max-h-93 xl:min-h-full overflow-auto xl:overflow-visible" id="menu">
              <nav className="w-full xl:w-auto">
                <div className="menu pt-0 lg:pt-0 ">
                  <ul className="flex justify-end m-0 flex-wrap">
                    <li className="w-full xl:w-auto  mb-0 ">
                      <Link title="Home" className=" text-black text-sm sm:text-sm md:text-sm lg:text-sm xl:text-base 2xl:text-xl font-normal hover:text-pink transition duration-150 ease-in-out py-2 px-4 xl:py-8 xl:px-0 block xl:inline-block border-t border-border-menu xl:border-none" to="/">Home</Link></li>
                    <li className="group w-full xl:w-auto  mb-0 ml-0 xl:ml-16 relative menu_hover">
                      <Link to="/services" title="Services" className="relative text-black text-sm sm:text-sm md:text-sm lg:text-sm xl:text-base 2xl:text-xl font-normal hover:text-pink transition duration-150 ease-in-out py-2 px-4 xl:py-8 xl:px-0 block xl:inline-block border-t border-border-menu xl:border-none">Services</Link>
                      <input className="hidden" type="checkbox" id="service-toggle"></input>
                      <ul id="servicemenu" className="opacity-0 invisible hidden xl:flex shadow-footer mega_menu relative xl:absolute bg-white p-0 xl:px-0 xl:py-0 w-full max-w-5xl top-100 left-1/2 transform -translate-x-2/4 min-w-min">
                        <div className="mega_menu_box flex flex-wrap">
                          <ul className="w-full">
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-white hover:border-pink px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm" to="/android-app-development-services" title="Android App Development Services">Android App Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/ios-app-development-services" title="iOS App Development Services">iOS App Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/flutter-app-development-services" title="Flutter App Development Services">Flutter App Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/codeigniter-web-development-services" title="Codeigniter Web Development Services">Codeigniter Web Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/php-web-development-services" title="PHP Web Development Services">PHP Web Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/graphics-design-services" title="Graphics Design Services">Graphics Design Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/laravel-web-development-services" title="Laravel Web Development Services">Laravel Web Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/magento-web-development-services" title="Magento Web Development Services">Magento Web Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/shopify-web-development-services" title="Shopify Web Development Services">Shopify Web Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/wordpress-web-development-services" title="Wordpress Web Development Services">Wordpress Web Development Services</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/quality-analysis" title="Quality Analysis">Quality Analysis</Link></li>
                            <li className=""><Link className="hover:bg-pink hover:text-white transition duration-150 ease-in-out whitespace-nowrap border-t border-border-menu px-8 py-2 xl:px-6 xl:py-3 block text-black leading-1 xl:leading-none text-sm " to="/digital-marketing" title="Digital Marketing">Digital Marketing</Link></li>
                          </ul>
                        </div>
                      </ul>
                    </li>
                    {/* <li className="w-full xl:w-auto  mb-0 ml-0 xl:ml-16"><Link className=" text-black text-sm sm:text-sm md:text-sm lg:text-sm xl:text-base 2xl:text-xl font-normal hover:text-pink transition duration-150 ease-in-out py-2 px-4 xl:py-8 xl:px-0 block xl:inline-block border-t border-border-menu xl:border-none" to="/hire-dedicated-developers" title="Hire Us">Hire Us</Link></li> */}
                    <li className="w-full xl:w-auto  mb-0 ml-0 xl:ml-16"><Link className=" text-black text-sm sm:text-sm md:text-sm lg:text-sm xl:text-base 2xl:text-xl font-normal hover:text-pink transition duration-150 ease-in-out py-2 px-4 xl:py-8 xl:px-0 block xl:inline-block border-t border-border-menu xl:border-none" to="/about-us" title="About Us">About Us</Link></li>
                    <li className="w-full xl:w-auto  mb-0 ml-0 xl:ml-16"><Link className=" text-black text-sm sm:text-sm md:text-sm lg:text-sm xl:text-base 2xl:text-xl font-normal hover:text-pink transition duration-150 ease-in-out py-2 px-4 xl:py-8 xl:px-0 block xl:inline-block border-t border-border-menu xl:border-none" to="/contact" title="Contact">Contact</Link></li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
